.aspect-ratio-square-image-box {
  width: 100%;
  padding-top: 100%;
  height: 0;
  position: relative;
  overflow: hidden;

  .image-item {
    max-width: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: auto;
    max-width: 100%;
    height: auto;
    // }
  }
}
