@import "./fonts/font.scss";

/* html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
} */
@tailwind base;

:root {
  --app-height: 100%;
  --app-overflow: "visible";
  --safe-area-height: calc(
    100vh - (env(safe-area-inset-bottom) + env(safe-area-inset-top))
  );
}

#__next {
  flex: 1 1 auto;
}

// body {
//   display: flex;
//   flex-direction: column;
// }

// //--app-height property used to fix 100vh issue in ios safari & android chrome for collections menu page only
html,
body {
  // position: relative;
  padding: 0;
  margin: 0;
  width: 100vw;
  // height: calc(
  //   100vh - (env(safe-area-inset-bottom) + env(safe-area-inset-top))
  // );
  // height: var(--app-height);
  // overflow: var(--app-overflow);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
}

/* override the default settings in tailwind */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline;
}

a {
  text-decoration: underline;
}

h1 {
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
h2 {
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
h3 {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
h4 {
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
h5 {
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
h6 {
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

ul {
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
ol {
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
p {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  font-size: 100% !important;
  line-height: inherit !important;
  font-size: inherit !important;
  height: 50px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* SCROLL BAR */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: grey;
  border: 0px none white;
}
::-webkit-scrollbar-thumb:hover {
  background: grey;
}
::-webkit-scrollbar-track {
  background: lightgrey;
  border: 0px solid lightgrey;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.no-scrollbar {
  @media (max-width: 640px) {
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.extra-thin-scrollbar {
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
}

.max-2-row {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* @layer base {
  html {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }
} */

@layer utilities {
  @responsive {
    .scrolling-touch {
      -webkit-overflow-scrolling: touch;
    }
    .scrolling-auto {
      -webkit-overflow-scrolling: auto;
    }
  }

  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.prevent-overscroll {
  -webkit-overflow-scrolling: touch;
}

.disable-webkit {
  -webkit-appearance: none;
}

.about-us-map {
  #map-container {
    height: 500px;
    width: 100%;
  }
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}
