@font-face {
  font-family: "DM Sans Regular";
  src: url("./template_1/DMSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "DM Sans Bold";
  src: url("./template_1/DMSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "DM Sans Medium";
  src: url("./template_1/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("./template_1/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Medium";
  src: url("./template_1/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins SemiBold";
  src: url("./template_1/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Bold";
  src: url("./template_1/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  src: url("./template_1/FiraSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans Black";
  src: url("./template_1/FiraSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans Bold";
  src: url("./template_1/FiraSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans BoldItalic";
  src: url("./template_1/FiraSans-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans ExtraBold";
  src: url("./template_1/FiraSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans ExtraBoldItalic";
  src: url("./template_1/FiraSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans ExtraLight";
  src: url("./template_1/FiraSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans ExtraLightItalic";
  src: url("./template_1/FiraSans-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans Italic";
  src: url("./template_1/FiraSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans Light";
  src: url("./template_1/FiraSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans LightItalic";
  src: url("./template_1/FiraSans-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans Medium";
  src: url("./template_1/FiraSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans MediumItalic";
  src: url("./template_1/FiraSans-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans SemiBold";
  src: url("./template_1/FiraSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans SemiBoldItalic";
  src: url("./template_1/FiraSans-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans Thin";
  src: url("./template_1/FiraSans-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans ThinItalic";
  src: url("./template_1/FiraSans-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Geist';
  src: url('./template_2/Geist-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./template_2/Geist-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./template_2/Geist-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./template_2/Geist-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./template_2/Geist-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}